import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";



function HomePage() {
  // let uri = process.env.REACT_APP_COGNITO_URI + 'login?client_id=' + process.env.REACT_APP_COGNITO_ID + 
  // '&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=' +
  // process.env.REACT_APP_REDIRECT_LOCAL + 'oauth-callback'
  // let uri = process.env.REACT_APP_COGNITO_URI_LOCAL
  
  return (
        <div>          
      
      <div className = "content-centered">
        <img id="logo-center"src={process.env.REACT_APP_GRAPHICS_S3 + 'logoName.png'} alt="Geolumina logo"></img>
        
        
        
        
        <div className = "row">
        <h3>NVZN Viewer</h3>

        <p>Visit <a href='https://geolumina.ai'>homepage</a> for more information</p>

        {/* <a className="col-sm login-button" href={uri}>Login</a>

        <a className="col-sm login-button" href={uri}>Logout</a> */}
  
        </div>

        </div>

      </div>
    );
}

export default HomePage;














