import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import React, { Component } from "react";
import HomePage from "./HomePage";
class App extends Component {
  render() {
    return (
      <HomePage />
        
    );
  }
}

export default App;